import React, {Component} from 'react';
import {addSurvey} from "../../redux/actions";
import {CustomMenuItem, FieldTitle, FormContainer, ImageContainer, ImageContentContainer, ImageInput,
  Layout, PageTitle, RemovePhotoButton, StyledInput,
  TitleContainer} from "../CreateEvent/styles";
import { ActivityDropdown, ActivityTypeContainer } from '../CreateChallengeForm/styles';
import SurveyQuestions  from '../SurveyQuestions'
import Waiting from "../Waiting";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import { surveyOptionTypes, /* challengePointItems */ } from '../../../mockData';
import {checkImage, getOrientation, resetOrientation} from "../../utils/methods";
import {imageErrorMessage} from "../../utils/constants";
import {toast} from "react-toastify";
import {isUndefined} from "lodash";
import CommonButton from '../common/CommonButton/CommonButton';
import CommonTextArea from '../common/CommonTextArea/CommonTextArea';

class CreateSurveys extends Component{
  constructor(props) {
    super(props);
    let pointsArray = this.challengePointItems;
    let indexExists = this.challengePointItems.findIndex((data) => data === 'No points');
    indexExists === -1 && pointsArray.unshift("No points");
    this.state = {
      title: '',
      imgSrc: '',
      imageName: '',
      points: 'No points',
      description:'',
      surveyPoints: pointsArray,
      error: [],
      hasSections: 1,
      prevSectionsData: [
        {
          "title": '',
          "data": [
            {
              "question": '',
              "options": surveyOptionTypes[0].options,
              "question_type": surveyOptionTypes[0].questionType
            }
          ]
        }
      ],
      prevQuestionsData: [
        {
          "question" : '',
          "options": surveyOptionTypes[0].options,
          "question_type": surveyOptionTypes[0].questionType
        }
      ],
      questions: [],
      buttonDisable: true,
      sections: [
        {
          "title": '',
          "data": [
            {
              "question" : '',
              "options": surveyOptionTypes[0].options,
              "question_type": surveyOptionTypes[0].questionType
            }
          ]
        }
      ]
    };
  }

  challengePointItems= [
    "Tier 1: 25 points",
    "Tier 2: 100 points",
    "Tier 3: 250 points",
    "Tier 4: 500 points"
  ]

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSelectPoints = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if(e.target.files[0]) {
      if(checkImage(fileArr)) {
        if((file?.size/1000000) <= 20){
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document.getElementById('survey-upload-file').value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage
                });
              });
            });
          };
        } else {
          toast.error('Please select image file less than 20MB');
          document.getElementById('survey-upload-file').value = '';
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById('survey-upload-file').value = '';
      }
    }
  };

  saveSurvey = (e) => {
    e.preventDefault();
    const{history, addSurvey} = this.props;
    const {title, imgSrc, points, description, hasSections, sections, questions} = this.state;
    let obj = {
      'title': title,
      'image': imgSrc,
      'description': description,
      'survey_point': points === 'No points' ? 0 : points === "Tier 1: 25 points" ? 25 : points === "Tier 2: 100 points" ? 100 : points === "Tier 3: 250 points" ? 250 : 500,
      'has_sections': hasSections,
      'data': hasSections !== 0 ? sections : questions
    };

    addSurvey(obj, history);
  };

  buttonDisableFunc = () => {
    const {hasSections} = this.state;
    if (hasSections !== 0) {
      let dupSections = [...this.state.sections];
      let emptyOption = dupSections.findIndex((list) =>
        (!isUndefined(list.title) && list.title.trim() === '') || list.data.length === 0 || ((list.data.findIndex((question) => question.question.trim() === '' || (question.question_type === 4 ? question.options.length !== 0 : question.options.length < 2 || (question.options.findIndex((option) => option.trim() === '') !== -1)))) !== -1)
      );

      if (emptyOption == -1 && dupSections.length >= 2) {
        this.setState({buttonDisable: false});
      }
      else {
        this.setState({buttonDisable: true});
      }
    } else {
      let dupQuestions = [...this.state.questions];
      let emptyOption = dupQuestions.findIndex((list) =>
        (!isUndefined(list.question) && list.question.trim() === '') || ( list.question_type === 4 ? list.options.length !== 0 : list.options.length < 2 || (list.options.findIndex((option) => option.trim() === '') !== -1))
      );

      if (emptyOption == -1 && dupQuestions.length >= 2) {
        this.setState({buttonDisable: false});
      }
      else {
        this.setState({buttonDisable: true});
      }
    }
  };

  updateSurveyQuestions = (questions) => {
    this.setState({
      questions: [...questions]
    }, () => this.buttonDisableFunc());
  };
  updateSurveySections = (sections) => {
    this.setState({
      sections: [...sections]
    }, () => this.buttonDisableFunc());
  };
  toggleSurveyRadioButton = (obj) => {
    this.setState(obj, () => this.buttonDisableFunc());
  };

  removePhoto = () => {
    document.getElementById('survey-upload-file').value = '';
    this.setState({
      imgSrc: '',
      imageName: '',
    });
  };
  onSelectSurveyType = (questionIndex, type, options, sectionIndex) => {
    const {sections, questions, hasSections} = this.state;
    if(hasSections !== 0){
      sections[sectionIndex].data[questionIndex]['options'] = (type === 2) ? [''] : options;
      sections[sectionIndex].data[questionIndex]['question_type'] = type;
      this.setState({sections: sections}, () => this.buttonDisableFunc());
    } else{
      questions[questionIndex]['options'] = (type === 2) ? [''] : options;
      questions[questionIndex]['question_type'] = type;
      this.setState({questions: questions}, () => this.buttonDisableFunc());
    }
  };
  render() {
    const {title, imageName, points, surveyPoints, description, imgSrc, hasSections, sections, questions, prevQuestionsData, prevSectionsData, buttonDisable} = this.state;
    const {isLoading} = this.props;
    if (isLoading) {
      return <Waiting />;
    }

    let isDisabled = false;
    if(description === '' || title === '' || imgSrc === '' || imageName === ''){
      isDisabled = true;
    }


    return (
      <Layout>
        <PageTitle>Create Survey Library</PageTitle>
        <div style={{float: 'left', width: '100%'}}>
          <FormContainer onSubmit={this.saveSurvey}>
            <TitleContainer>
              <FieldTitle>Survey Title:</FieldTitle>
              <StyledInput
                placeholder="Type title here..."
                name="title"
                onChange={this.onChangeInput}
                value={title}
                maxLength="100"
                type="text"
              />
            </TitleContainer>
            <ImageContainer>
              <FieldTitle>Survey Picture:</FieldTitle>
              <div>
                { imageName &&
                <div>
                  <img src={imgSrc} />
                  <ImageContentContainer>
                    <div>{imageName}</div>
                    <RemovePhotoButton color="#aaaaaa" type="button" onClick={this.removePhoto}>Remove photo</RemovePhotoButton>
                  </ImageContentContainer>
                </div>
                }
                <ImageInput htmlFor="survey-upload-file" showAtBottom={imageName !== ''}>
                  {imageName ? 'browse new' : 'browse'}
                  <input
                    id="survey-upload-file"
                    type="file"
                    name="user"
                    accept=".jpeg, .png, .jpg"
                    multiple={false}
                    onChange={(e) => this.onChange(e)}
                    onClick={(e) => e.target.files[0] && this.onChange(e)}
                  />
                </ImageInput>
              </div>
            </ImageContainer>
            <TitleContainer>
              <ActivityTypeContainer>
                <FieldTitle>Point Value:</FieldTitle>
                <ActivityDropdown
                  title={points}
                  id="dropdown-basic"
                >
                  {
                    surveyPoints.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onSelect={() => this.onSelectPoints('points', item)}
                        active={points === item}
                      >
                        {item}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </ActivityTypeContainer>
            </TitleContainer>
            <TitleContainer>
              <FieldTitle>Survey Description:</FieldTitle>
              <CommonTextArea
                placeholder="Write your description here..."
                rows="8"
                name="description"
                value={description}
                onChange={this.onChangeInput}
                data-gramm_editor="false"
                type="text"
              />
            </TitleContainer>
            <SurveyQuestions
              questions={questions}
              sections={sections}
              prevQuestionsData={prevQuestionsData}
              prevSectionsData={prevSectionsData}
              hasSections={hasSections}
              toggleSurveyRadioButton={this.toggleSurveyRadioButton}
              updateSurveyQuestions={this.updateSurveyQuestions}
              updateSurveySections={this.updateSurveySections}
              onSelectSurveyType={this.onSelectSurveyType}
            />
            <ImageContainer>
              {/* <SaveButton >Save Survey</SaveButton> */}
              <CommonButton 
                btnType={"rounded"}
                type="submit" 
                disabled={isDisabled || buttonDisable} 
                addMargin
                styles={{width:'158px', height:'35px', marginBottom:'55px'}}
                title={"Save Survey"}
              />
            </ImageContainer>
          </FormContainer>
        </div>
      </Layout>

    );
  }
}
CreateSurveys.propTypes = {
  history: PropTypes.object,
  addSurvey: PropTypes.func,
  isLoading: PropTypes.bool
};
const mapStateToProps = (state) => ({
  isLoading: state.surveys.isLoading
});
const mapDispatchToProps = (dispatch) => ({
  addSurvey: (data, history) => dispatch(addSurvey(data, history))
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateSurveys);