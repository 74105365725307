import styled from 'styled-components';
import {RadioButtonContainer} from "../CreateRecipe/styles";
import { RepeatContainer } from '../BehaviorChallenge/styles';
const ChoiceContainer = styled.div`
  height: 100%;
  float: left;
`;

const SectionContainer = styled.div`
  float: left;
  background-color: white;
  padding: 25px;
  border-radius: 4px;
`;

const RadioButtonSurveyConatiner = styled(RadioButtonContainer)`
  justify-content: flex-start;
`;
const RepeatContainerOptions = styled(RepeatContainer)` 
  display: flex;
  align-items: center;
  justify-content: flex-end;
  float: right;
  margin: 15px;
  width: 100%;
`;


export { ChoiceContainer, SectionContainer, RadioButtonSurveyConatiner, RepeatContainerOptions};