import styled, {css} from "styled-components";
import {FieldTitle} from '../CreateEvent/styles'
import {RadioButtonWrapper} from "../ChallengeDetailsV2/ChallengeLeaderboardV2/styles";
import { DropdownButton } from "react-bootstrap";
const RegularFont = 'Rubik-Regular';
const TimeContainer = styled.div`
  width: 100%;
  margin-top: 15px;
`;

const IngredientButtonContainer = styled.div`
  width: 25% !important;
  margin-top: 15px;
  @media (max-width: 1150px){
    width: 100% !important;
  }
`;
const AddButton = styled.div`
  font-size: 14px;
  font-family: Rubik-Regular, sans-serif;
  width: 150px;
  height: 46px;
  background-color: #159fc9;
  border: 1px solid #159fc9;
  color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 6px;
  margin-left: 10px;
  cursor: pointer;
  @media (max-width: 1150px){
   margin: 10px auto;
  }
`;
const CustomInputIngredients = styled.input`
  margin-top: 15px;
  font-size: 16px;
  padding: 12px 10px;
  border-radius: 4px;
  border: 1px solid #cdcbcb;
  float: left;
  width: 75%;
  height: 46px;
  @media (max-width: 1150px){
    width: 100%;
  }
  &:hover, &:active, &:focus {
    outline: none;
  }
`;
const FieldTitleIngredients = styled(FieldTitle)`
  width: 100%;
`;
const ShowIngredientContent = styled.div`
  width: 100%;
  color: #000;
  background-color: #fff;
  display: flex;
  margin: 10px 0;
  align-items: center;
  flex-direction: column;
  >div{
    display: flex;
    align-items: center;
    width: 100%;
    > div{
      width: 75%;
      height: auto;
      border-radius: 6px;
      display: flex;
      align-items: center;
      font-family: ${RegularFont};
      font-size: 16px;
      border: 1px solid #cdcbcb;
      padding: 10px;
      flex-wrap: wrap;
      flex-basis: 75%
      background-color: #f3f3f3;
      @media (max-width: 1150px){
        width: 100%;
        flex-basis: 100%
      }
    }
     > i{
        float: right;
        margin-left: 5px;
        font-size: 30px;
        color: red;
        cursor: pointer;
      }
  }
`;
const ShowDirectionContent = styled.div`
  width: 100%;
  color: #000;
  background-color: #fff;
  display: flex;
  margin: 10px 0;
  align-items: center;
  flex-direction: column;
  >div: first-child{
    color: rgb(153,153,153);
    font-family: ${RegularFont};
    font-size: 15px;
    width: 100%;
  }
  >div: last-child{
    display: flex;
    align-items: center;
    width: 100%;
    > div{
      width: 75%;
      height: auto;
      border-radius: 6px;
      display: flex;
      align-items: center;
      font-family:${RegularFont};
      font-size: 16px;
      border: 1px solid #cdcbcb;
      padding: 10px;
      background-color: #f3f3f3;
      flex-wrap: wrap;
      flex-basis: 75%;
      @media (max-width: 1150px){
        width: 100%;
        flex-basis: 100%
      }
    }
     > i{
        float: right;
        margin-left: 5px;
        font-size: 30px;
        color: red;
        cursor: pointer;
      }
  }
`;
const MultipleImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  float: left;
  align-items: center;
  > img{
    width: 150px;
    height: 150px;
  }
`;
const ColonSeparator = styled.span`
  top: 8px;
  font-size: 20px;
  position: absolute;
  display: block;
  left: 85px;
`;
const RadioButtonContainer = styled(RadioButtonWrapper)`
   display: flex;
  //  padding: 20px 0 0 0;
   ${({justifyContent}) => justifyContent && css`
      justify-content: ${justifyContent};
  `}
   > div:first-child {
    @media (max-width:600px) {
      margin-bottom: 10px;
    }
   }
   @media (max-width:1040px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0 0 0;
   }
`;

const ActivityDropdown = styled(DropdownButton)`
  padding: 0px 0px; // 12px 10px;
  font-size: 14px;
  width: 100%;
  text-align: left;
  margin-top: 15px;
  color: rgb(153,153,153);
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  button{
    font-size: 14px;
    text-align: left;
    width: 100%;
    height: 50px;
    outline: none;
    background-color: white;
    border-color: rgb(204, 204, 204);
    box-shadow: none;
    color: rgb(153, 153, 153);
    &::after{
      float: right;
      right: 20px; 
      top: 23px; // 30px;
      position: absolute;
    }
    &:hover, &:active, &:focus{
      outline: none;
      background-color: white;
      border-color: rgb(204, 204, 204);
      box-shadow: none;
      color: rgb(153, 153, 153);
    }
    &:active:focus{
      color: #333;
      background-color: #d4d4d4;
      border-color: #8c8c8c;
    }
  }
  > span {
    float: right;
    top: 20px;
    position: absolute;
    right: 10px;
    height:100px;
    overflex:scroll;
  }

  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: white;
    border-color: #ccc;
    box-shadow: none;
    color: rgb(153,153,153);
  }

  .dropdown-menu.show{
    font-size: 14px;
    position: ${ ({isCreateRecipe}) => {isCreateRecipe && "relative !important"} };
  }

  .dropdown-item.active{
    color: #fff;
    text-decoration: none;
    background-color: #337ab7;
    outline: 0;
  }

  button.show{
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
`;

const ActivityTypeContainer = styled.div`
  width: ${({ width }) => width ? width : '40% !important'};
  padding: 0;
  float: ${({float}) => float ? float : 'none'};
  margin: ${({margin}) => margin ? margin : '0px'};
  > span {
    width: ${({ width }) => width && '100%' };
  }
  > div {
    width: ${({ noChildWidth }) => noChildWidth ? '100%' : '95%' };
    max-width: ${({ noChildWidth }) => noChildWidth ? 'unset' : '310px' };
    margin-right:  ${({ noChildWidth }) => noChildWidth ? '0px' : '5%' };
    display: ${({ display }) => display ? 'flex' : 'block'};
    float: left;
  }

  @media (max-width: 550px) {
    ${({ challengeMode }) => challengeMode && css`
      margin-top: 15px;
    `}
  }
  
  @media (max-width: 640px) {
    width: 100% !important;
    margin-top: 0
  }

  .dropdown-menu {
    width: 100%;
    height: 280px;
    overflow: scroll;
  }
  .dropdown > div{
    position: relative !important;
    transform: none !important;
  }
`;

export {TimeContainer, AddButton, CustomInputIngredients, FieldTitleIngredients, IngredientButtonContainer, ShowIngredientContent, ShowDirectionContent, MultipleImageContainer, ColonSeparator, RadioButtonContainer, ActivityDropdown, ActivityTypeContainer};